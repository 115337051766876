@import "../variables/index";
@import "../mixins/index";
@import "fonts";
@import "grid";
@import "container";

* {
  box-sizing: border-box;
  min-width: 1px;
  min-height: 1px;
}
html {
  box-sizing: border-box;
  font-family: $base-font;
  font-size: 16px;
  line-height: 1.3;
}
body {
  min-width: $mobile-width;
  margin: 0;
  height: 100%;
}
.no-scroll {
  overflow: hidden;
}

.react-datepicker {
  &-wrapper {
    width: 100%;
  }
  &__tab-loop {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__input-container {
    & > input[type="text"] {
      width: 100%;
      padding: 9px 39px 9px 11px;
      font-size: 14px;
      line-height: 1.3;
      background: transparent url("@public/images/icons/datepicker-icon.svg") calc(100% - 11px)
        center / 18px 18px no-repeat;
      border: none;
      &::-webkit-input-placeholder {
        color: $dark-grey;
      }
      &::-moz-placeholder {
        color: $dark-grey;
      }
      &:-moz-placeholder {
        color: $dark-grey;
      }
      &:-ms-input-placeholder {
        color: $dark-grey;
      }
    }
  }
}

div,
a,
nav,
ul,
li {
  box-sizing: border-box;
  transition: 0.3s;
}
.company-user-avatar-fix {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5);
}
.tac {
  text-align: center;
}

#root {
  white-space: pre-line;
}
img {
  max-width: 100%;
  font-size: 1em;
}
a {
  text-decoration: none;
  color: $dark-grey;
}

h2 {
  @include reset;
  @include h48;
}

h3 {
  @include reset;
  @include h36;
}

#userBar {
  display: none;
}

#app,
#root {
  min-height: 100vh;
}
.langing {
  min-height: 100vh;
}
input[type="text"] {
  @include fs14;
  border: 1px solid $grey-e5;
}
.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.text-red {
  color: $red;
}
#jaas-container {
  height: 100%;
}

.schedule-experts {
  .swiper-slide {
    width: auto !important;
  }

  .swiper-button-disabled {
    visibility: hidden;
  }
}
